import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w02TA05BettaSplendens = () => (
  <Layout>
    <SEO title="w02TA05BettaSplendens" />

    <h1 id="ueberschrift">Tiere im Alltag</h1>
    <h2>Der Kampffisch (Betta) &emsp;  ©&nbsp;2002</h2>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w02TA05BettaSplendens&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p className="poem">
        Ein kleiner Fisch voll Farbenpracht<br />
        vom Reisfeld einst hierher gebracht<br />
        wurde zum Spaß gezüchtet,<br />
        da er meist kämpft statt flüchtet.<br />
        <div style={{fontSize: "0.75em"}}>BC &emsp; ©&nbsp;2002</div>
      </p>

    <p>---</p>

      <p>  
        Das neue Aquarium wurde liebevoll eingerichtet. Die Pflanzen und Fische scheinen sich wohl zu fühlen ...
        passt dazu nun solch ein farbenfroher Fisch mit einem langen Schleierschwanz?
        Sie tragen den Namen "Kampffisch" zurecht und Ihr Schwanz ist für andere Fische manchmal ein "tolles Spielzeug".
        Was nun?
      </p>
      <p>
        Meist ist es einfacher etwas zu verstehen, das erlebt oder zumindest angeschaut werden kann.
        Aus diesem Grund bietet dieser Kurs mehr als einen einfachen Frontal-Vortrag. Durch die Mischung meiner
        Kenntnisse mit Ihren Situationen und Interessen können wir den Kursinhalt weitmöglichst Ihren Bedürfnissen anpassen.
        So können Sie die Eigenheiten dieser besonderen Fische kennenlernen und gegebenenfalls eine Strategie entwickeln,
        wie Sie einen "Betta" in Ihrem Aquarium zu Hause beheimaten.
      </p>
      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Vortrag (+Mitmachkurs)
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Tisch pro Teilnehmer + 1 Tisch extra<br/>
        Materialbedarf: keiner
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 1 Tisch
      </p>
      <p>
        + keine besonderen Voraussetzungen nötig +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w02TA05BettaSplendens
